@font-face {
    font-family: Montserrat Black;
    src: url(fonts/montserrat/Montserrat-Black.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat BlackItalic;
    src: url(fonts/montserrat/Montserrat-BlackItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Bold;
    src: url(fonts/montserrat/Montserrat-Bold.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat BoldItalic;
    src: url(fonts/montserrat/Montserrat-BoldItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat ExtraBold;
    src: url(fonts/montserrat/Montserrat-ExtraBold.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat ExtraBoldItalic;
    src: url(fonts/montserrat/Montserrat-ExtraBoldItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat ExtraLight;
    src: url(fonts/montserrat/Montserrat-ExtraLight.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat ExtraLightItalic;
    src: url(fonts/montserrat/Montserrat-ExtraLightItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Italic;
    src: url(fonts/montserrat/Montserrat-Italic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Light;
    src: url(fonts/montserrat/Montserrat-Light.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat LightItalic;
    src: url(fonts/montserrat/Montserrat-LightItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Medium;
    src: url(fonts/montserrat/Montserrat-Medium.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat MediumItalic;
    src: url(fonts/montserrat/Montserrat-MediumItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Regular;
    src: url(fonts/montserrat/Montserrat-Regular.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat SemiBold;
    src: url(fonts/montserrat/Montserrat-SemiBold.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat SemiBoldItalic;
    src: url(fonts/montserrat/Montserrat-SemiBoldItalic.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat Thin;
    src: url(fonts/montserrat/Montserrat-Thin.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Montserrat ThinItalic;
    src: url(fonts/montserrat/Montserrat-ThinItalic.ttf);
    font-weight: normal;
  }
  