@font-face {
  font-family: FuturaPT Bold;
  src: url(fonts/FuturaPT/FuturaPT-Bold.ttf),
    url(fonts/FuturaPT/FuturaPT-Bold.woff);
  font-weight: normal;
}

@font-face {
  font-family: FuturaPT Book;
  src: url(fonts/FuturaPT/FuturaPT-Book.ttf),
    url(fonts/FuturaPT/FuturaPT-Book.woff);
  font-weight: normal;
}

@font-face {
  font-family: FuturaPT Demi;
  src: url(fonts/FuturaPT/FuturaPT-Demi.ttf),
    url(fonts/FuturaPT/FuturaPT-Demi.woff);
  font-weight: normal;
}

@font-face {
  font-family: FuturaPT Heavy;
  src: url(fonts/FuturaPT/FuturaPT-Heavy.ttf),
    url(fonts/FuturaPT/FuturaPT-Heavy.woff);
  font-weight: normal;
}

@font-face {
  font-family: FuturaPT Medium;
  src: url(fonts/FuturaPT/FuturaPT-Medium.ttf),
    url(fonts/FuturaPT/FuturaPT-Medium.woff);
  font-weight: normal;
}