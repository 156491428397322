@font-face {
  font-family: Gotham Black;
  src: url(fonts/Gotham/Gotham-Black.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Bold;
  src: url(fonts/Gotham/Gotham-Bold.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham BookItalic;
  src: url(fonts/Gotham/Gotham-BookItalic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Light;
  src: url(fonts/Gotham/Gotham-Light.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Thin;
  src: url(fonts/Gotham/Gotham-Thin.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham ThinItalic;
  src: url(fonts/Gotham/Gotham-ThinItalic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham UltraItalic;
  src: url(fonts/Gotham/Gotham-UltraItalic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham XLight;
  src: url(fonts/Gotham/Gotham-XLight.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham XLightItalic;
  src: url(fonts/Gotham/Gotham-XLightItalic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Bold;
  src: url(fonts/Gotham/GothamBold.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham BoldItalic;
  src: url(fonts/Gotham/GothamBoldItalic.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Book;
  src: url(fonts/Gotham/GothamBook.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham BookItalic;
  src: url(fonts/Gotham/GothamBookItalic.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Light;
  src: url(fonts/Gotham/GothamLight.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham LightItalic;
  src: url(fonts/Gotham/GothamLightItalic.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Medium_1;
  src: url(fonts/Gotham/GothamMedium_1.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham Medium;
  src: url(fonts/Gotham/GothamMedium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Gotham MediumItalic;
  src: url(fonts/Gotham/GothamMediumItalic.ttf);
  font-weight: normal;
}
