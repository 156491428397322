@font-face {
    font-family: Futura Light;
    src: url(fonts/Futura_Light_font.ttf);
    font-weight: normal;
  }

  @font-face {
    font-family: Futura Light Italic;
    src: url(fonts/Futura_Light_Italic_font.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Futura Bold;
    src: url(fonts/Futura_Bold_font.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Futura Bold Italic;
    src: url(fonts/Futura_Bold_Italic_font.ttf);
    font-weight: normal;
  }

  @font-face {
    font-family: Futura Medium;
    src: url(fonts/futura_medium_bt.ttf);
    font-weight: normal;
  }
  @font-face {
    font-family: Futura Medium Italic;
    src: url(fonts/Futura_Medium_Italic_font.ttf);
    font-weight: normal;
  }