@font-face {
  font-family: FordAntenna;
  src: url(fonts/FordAntenna/FordAntenna-Regular.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna Italic;
  src: url(fonts/FordAntenna/FordAntenna-RegularItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna Bold;
  src: url(fonts/FordAntenna/FordAntenna-Bold.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna BoldItalic;
  src: url(fonts/FordAntenna/FordAntenna-BoldItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondBold;
  src: url(fonts/FordAntenna/FordAntenna-CondBold.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondBoldItalic;
  src: url(fonts/FordAntenna/FordAntenna-CondBoldItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondLight;
  src: url(fonts/FordAntenna/FordAntenna-CondLight.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondLightItalic;
  src: url(fonts/FordAntenna/FordAntenna-CondLightItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondMedium;
  src: url(fonts/FordAntenna/FordAntenna-CondMedium.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondMediumItalic;
  src: url(fonts/FordAntenna/FordAntenna-CondMediumItalic.otf);
  font-weight: normal;
}

@font-face {
  font-family: FordAntenna CondRegular;
  src: url(fonts/FordAntenna/FordAntenna-CondRegular.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna CondRegularItalic;
  src: url(fonts/FordAntenna/FordAntenna-CondRegularItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna Light;
  src: url(fonts/FordAntenna/FordAntenna-Light.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna LightItalic;
  src: url(fonts/FordAntenna/FordAntenna-LightItalic.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna Medium;
  src: url(fonts/FordAntenna/FordAntenna-Medium.otf);
  font-weight: normal;
}
@font-face {
  font-family: FordAntenna MediumItalic;
  src: url(fonts/FordAntenna/FordAntenna-MediumItalic.otf);
  font-weight: normal;
}
