@import "./variables";
.page-container {
    padding: 25px 25px 40px 25px;
    .page-inner-container {

        max-width: $desktop-tablet-width;
        margin: 0 auto;

        .page-title {
            text-align: center;
            h1 {
                font-size: $brand-typeface-h1-size;
            }
        }

        .flex-container {
            display: flex;
            justify-content: center;
            .flex-item {
                flex: 0 1 $mobile-width;
            }
        }
    }
}
