/* You can add global styles to this file, and also import other style files */

@import "material-icon-fonts.scss";
@import "scss/_variables.scss";
@import "scss/material.themes";
@import "scss/common.scss";
@import "scss/quote-question.scss";
@import "scss/user-details.scss";
@import "futura-fonts.scss";
@import "montserrat-fonts.scss";
@import "FordAntenna-font.scss";
@import "LatamAirlinesBR-font.scss";
@import "Graphik-font.scss";
@import "LyonDisplay-font.scss";
@import "DIRECTCurve-font.scss";
@import "Gotham-font.scss";
@import "FuturaPT-font.scss";
