:root {
  /*** Brand colors ***/
  --BRAND-ACCENT-1: #711984;
  --BRAND-ACCENT-2: #ffb900;
  --PRIMARY-CTA-HOVER: #390b43;
  --PRIMARY-CTA-ACTIVE: #27082e;
  --COOKIE-BANNER-BACKGROUND-COLOR: #d4bada;
  --COOKIE-BANNER-TEXT-COLOR: black;
  --BRAND-BANNER-BACKGROUND-COLOR: #e9fbff;
  /*** Brand typeface family ***/
  --MENU-ITEM-COLOR: black;
  --MENU-ACTIVE-ITEM-COLOR: black;
  --PAGE-BACKGROUND-COLOR: #fff;
  --BRAND-TYPEFACE-FAMILY: Roboto, "Helvetica Neue", sans-serif;
  --HEADER-FONT-FAMILY: Arial, Helvetica, sans-serif;
  --BUTTON-BORDER-RADIUS: 0;
  --FOOTER-BACKGROUND-COLOR: #212529;
  --FOOTER-TEXT-COLOR: white;
  --BUTTON-LABEL-COLOR: white;
  --STRAPLINE-HEADER-COLOR: red;
  --STRAPLINE-TEXT-COLOR: black;
  --PRIMARY-HEADER-COLOR: black;
  --SECONDARY-HEADER-COLOR: black;
  --TERTIARY-HEADER-COLOR: black;
  --CAPTION-COLOR: black;
  --BREADCRUMB-HEADER-COLOR: black;
  --BREADCRUMB-QUESTION-COLOR: black;
  --BREADCRUMB-ANSWER-COLOR: black;
  --BREADCRUMB-DEFAULT-TEXT-COLOR: black;
  --HEADER-BACKGROUND-COLOR: #fff;
  --TILE-ODD-BACKGROUND-COLOR: #ffda31;
  --TILE-EVEN-BACKGROUND-COLOR: #e4e3e1
}

/*** Fixed Colours ***/
$white-color: #fff;
$black-color: #090909;
$disabled-and-dividers: #c7c6cb;
$off-black-color: #212529;
$caption-text-color: #63656b;
$caption-text-color-light: #929292;
$bg-grey-color: #f6f6f6;
$bg-disabled-grey-color:#eeeeee;
$interaction-grey-color: #e9ecef;
$error-color: #db1829;
$success-added-color: #00c488;
$kyc-message-color: #327fef;
$failure-added-color: #ff0000;
$navitem-color: #292121;
$accordion-hover-color: $caption-text-color;
$accordion-focus-color: var(--BRAND-ACCENT-1);
$card-type-background-color: #eee;

$info-color: #35B0EF;
$warning-color: #ff6f00;
$light-accent-primary: #D4BCDA;

/*** Brand typeface family ***/
$brand-typeface-family: Roboto, "Helvetica Neue", sans-serif !default;
$header-font-family: Arial, Helvetica, sans-serif;

/*** Brand typeface font size ***/
$brand-desktop-header-size: 57px;
$brand-tablet-header-size: 46px;
$brand-mobile-header-size: 34px;
$brand-typeface-h1-size: 36px;
$brand-typeface-h2-size: 26px;
$brand-typeface-h3-size: 15px;
$brand-typeface-caption-size: 14px; /*** Applicable to header and body captions ***/
$brand-typeface-body-size: 15px;

/*** Brand typeface font line height ***/
$brand-typeface-h1-line-height: 122%;
$brand-typeface-h2-line-height: 131%;
$brand-typeface-h3-line-height: 147%;
$brand-typeface-caption-line-height: 157%; /*** Applicable to header and body captions ***/
$brand-typeface-body-line-height: 147%;

$brand-paragraph-line-height: 20px;
$brand-typeface-h2-small-line-height: 22px;
$brand-typeface-h3-small-line-height: 24px;
$brand-typeface-h2-medium-line-height: 34px;
$brand-typeface-h2-large-line-height: 44px;
$brand-typeface-h1-large-line-height: 56px;

/* Desktop Config */
$desktop-tablet-width: 710px;

/* Mobile  Config */
$mobile-width: 440px;

/* branding Logo */
$branding-logo_max-width: 240px;
$branding-logo_max-height: 56px;

/* These need to go! */
$header-toolbar-border-color: #d5d5d5;
