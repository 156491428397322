@import "./variables";

html,
body {
    margin: 0;
    padding: 0;
    background-color: var(--PAGE-BACKGROUND-COLOR);
    font-family: var(--BRAND-TYPEFACE-FAMILY);
    font-size: $brand-typeface-body-size;
    font-weight: normal;
}

main {
    min-height: 70%;
    min-height: -webkit-calc(100% - 145px);
    min-height: -moz-calc(100% - 145px);
    min-height: calc(100% - 145px);
}
.home-content-wrapper {
    padding: 50px 25px 25px 25px;
}

/* primary input data question container of homepage */
.quoteQuestionMain {
    background: var(--BRAND-ACCENT-1);
    padding: 100px 25px;
}

// --- heading css(home sections)
.brand-h2 {
    font-family: var(--BRAND-TYPEFACE-FAMILY);
    font-weight: bold;
    padding-bottom: 5px;
}
.medium-title {
    font-size: $brand-typeface-h2-size;
    line-height: 34px;
}
.large-title {
    font-size: $brand-typeface-h1-size;
    line-height: 44px;
}
// --- end of heading css

.border-radius {
    border-radius: 5px;
}

/* placeholder stylings*/
.form-control::-webkit-input-placeholder {
    color: var(--BRAND-ACCENT-2);
    font-size: $brand-typeface-body-size;
}

// -- common class style to make cursor pointer
.cursor-pointer {
    cursor: pointer;
}

// ---- disable the outline around in element
.btn:focus,
button:focus,
a,
.mat-expansion-panel,
.mat-icon {
    outline: none;
    box-shadow: none;
}

/* mdc-dialog__container styles*/
.mdc-dialog__container {
    background-color: $white-color;
    border-radius: 0 !important;
    // position: absolute;
    // top: 0;
    // left: 0;
    // min-width: 100vw;
    // min-height: 100vh;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 24px; // to add the padding in modal 
}

@media screen and (min-width: 412px)and (max-width: 915px) {

    .dialog-content-css {
        .mdc-dialog__container {
            max-width: 100vw !important;
            height: 500px;
        }

        .mat-mdc-dialog-content {
            width: 250px;
        }

        top: 10% !important;
        position: absolute !important;
    }
}

@media screen and (min-width: 375px)and (max-width: 667px) {

    .dialog-content-css {
        .mdc-dialog__container {
            max-width: 100vw !important;
            height: 500px;
        }

        .mat-mdc-dialog-content {
            width: 250px;
        }

        top: 10% !important;
        position: absolute !important;
    }
}
.full-width-dialog {
    max-width: 100vw !important;
    .mdc-dialog__container {
        max-width: 100vw !important;
    }
    top: 10% !important;
    position: absolute !important;
}

/* mat-toolbar styles*/
.mat-toolbar {
    border-bottom: 1px solid $header-toolbar-border-color;
}

/*  Material Icons Styling */
/* mat icon*/
mat-icon {
    color: var(--BRAND-ACCENT-1);
}
/* Material Icons sizing */
.material-icons {
    color: var(--BRAND-ACCENT-1);
}
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}
.material-icons.md-60 {
    font-size: 60px;
}
/* End of material icons tyling */

/* **** High-Def styling(common) to mat expansion panel(FAQ homepage)   ****** */
.mat-expansion-panel {
    border-top: 1px solid $header-toolbar-border-color;
    border-bottom: 1px solid $white-color;
}
/* Removed important because its blocking to override component level */
.mat-expansion-panel:hover {
    border-top: 1px solid $accordion-hover-color; 
    border-bottom: 1px solid $accordion-hover-color;
}
.mat-expansion-panel:focus {
    border-top: 1px solid $accordion-hover-color !important;
    border-bottom: 1px solid $accordion-hover-color !important;
}
.mat-expansion-panel.mat-expanded:hover {
    border-top: 1px solid $accordion-hover-color !important;
    border-bottom: 1px solid $accordion-hover-color !important;
}

.mat-bottom-sheet-container {
    background-color: $white-color;
    min-width: 100vw !important;
    min-height: 100vh;
    padding: 0 !important;
}

.desktop_title_size {
    font-size: $brand-desktop-header-size !important;
}

/* help text styles*/
.help-text {
    color: var(--BRAND-ACCENT-1);
    font-size: $brand-typeface-body-size;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
}

.help-text:hover {
    color: var(--PRIMARY-CTA-HOVER);
    background-color: $interaction-grey-color;
}

.help-text:active {
    color: var(--PRIMARY-CTA-HOVER);
    background-color: $interaction-grey-color;
}

/*Device based styles*/
.desktop-component-container {
    display: flex;
}

.main-container {
    flex: 1;
}

.tablet-component-container {
    display: flex;
    flex-direction: column;
}

/* header styles */
.screen-header {
    font-family: var(--HEADER-FONT-FAMILY) !important;
    font-weight: bold !important;
    margin-bottom: 60px !important;
}
.desktop-header-size {
    font-size: $brand-desktop-header-size !important;
}

.tablet-header-size {
    font-size: $brand-tablet-header-size !important;
}

.mobile-header-size {
    font-size: $brand-mobile-header-size !important;
}

/* Spinner styling */

.spinner-container {
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.header-icon-container {
    padding: 5px 0;
}

.cdk-global-scrollblock {
    position: static;
    overflow-y: hidden !important;
}

.primary-header {
    color: var(--PRIMARY-HEADER-COLOR);
}
.secondary-header {
    color: var(--SECONDARY-HEADER-COLOR);
}
.tertiary-header {
    color: var(--TERTIARY-HEADER-COLOR);
}
.section-caption {
    color: var(--CAPTION-COLOR);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
.cdk-keyboard-focused .mat-calendar-body-active,
.cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content {
        color: $white-color !important;
    }
}

.mat-calendar-body-cell-content:hover {
    color: $white-color;
}

.upload_doc .mat-card {
    border-top: none !important;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

#ngb-live{
    display: none;
}

