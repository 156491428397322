@font-face {
  font-family: LyonDisplay Medium;
  src: url(fonts/LyonDisplay/LyonDisplay-Medium.otf);
  font-weight: normal;
}

@font-face {
  font-family: LyonDisplay;
  src: url(fonts/LyonDisplay/LyonDisplay-Regular.otf);
  font-weight: normal;
}
