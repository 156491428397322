@font-face {
  font-family: DirectvCurve;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Regular.otf);
  font-weight: normal;
}
@font-face {
  font-family: DirectvCurve Italic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Italic.otf);
  font-weight: normal;
}
@font-face {
  font-family: DirectvCurve Bold;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Bold.otf);
  font-weight: normal;
}
@font-face {
  font-family: DirectvCurve BoldItalic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Bold_Italic.otf);
  font-weight: normal;
}
@font-face {
  font-family: DirectvCurve Light;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Light.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve LightItalic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Light_Italic.otf);
  font-weight: normal;
}
@font-face {
  font-family: DirectvCurve Black;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Black.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve BlackItalic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Black_Italic.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve Medium;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Medium.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve MediumItalic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Medium_Italic.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve Regular;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve Ultra;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Ultra.otf);
  font-weight: normal;
}

@font-face {
  font-family: DirectvCurve UltraItalic;
  src: url(fonts/DirectvCurve/DIRECTVCurve-Ultra_Italic.otf);
  font-weight: normal;
}
