@font-face {
  font-family: Graphik Light;
  src: url(fonts/Graphik/Graphik-Light.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik LightItalic;
  src: url(fonts/Graphik/Graphik-Light-Italic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik Medium;
  src: url(fonts/Graphik/Graphik-Medium.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik MediumItalic;
  src: url(fonts/Graphik/Graphik-Medium-Italic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik;
  src: url(fonts/Graphik/Graphik-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik RegularItalic;
  src: url(fonts/Graphik/Graphik-Regular-Italic.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik Semibold;
  src: url(fonts/Graphik/Graphik-Semibold.otf);
  font-weight: normal;
}

@font-face {
  font-family: Graphik SemiboldItalic;
  src: url(fonts/Graphik/Graphik-Semibold-Italic.otf);
  font-weight: normal;
}
