@font-face {
  font-family: LatamAirlinesBR;
  src: url(fonts/LatamAirlinesBR/latam_sans_regular-webfont.ttf);
  font-weight: normal;
}
@font-face {
  font-family: LatamAirlinesBR Italic;
  src: url(fonts/LatamAirlinesBR/latam_sans_regular_italic-webfont.ttf);
  font-weight: normal;
}
@font-face {
  font-family: LatamAirlinesBR Bold;
  src: url(fonts/LatamAirlinesBR/latam_sans_bold-webfont.ttf);
  font-weight: normal;
}
@font-face {
  font-family: LatamAirlinesBR BoldItalic;
  src: url(fonts/LatamAirlinesBR/latam_sans_bold_italic-webfont.ttf);
  font-weight: normal;
}
@font-face {
  font-family: LatamAirlinesBR Light;
  src: url(fonts/LatamAirlinesBR/latam_sans_light-webfont.ttf);
  font-weight: normal;
}

@font-face {
  font-family: LatamAirlinesBR LightItalic;
  src: url(fonts/LatamAirlinesBR/latam_sans_light_italic-webfont.ttf);
  font-weight: normal;
}
@font-face {
  font-family: LatamAirlinesBR Black;
  src: url(fonts/LatamAirlinesBR/latam_sans_black-webfont.ttf);
  font-weight: normal;
}

@font-face {
  font-family: LatamAirlinesBR BlackItalic;
  src: url(fonts/LatamAirlinesBR/latam_sans_black_italic-webfont.ttf);
  font-weight: normal;
}
