/* styles added in by dev */
@use '@angular/material/' as mat ;
@import './variables.scss' ;
@include mat.core();
$custom-typography: mat.define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif !default'
);



$brand-accent-1: (
    
    100:var(--white-color),
    700:var(--black-color),
    500: var(--BRAND-ACCENT-1),
    contrast: (
        500: white
    )
);

$brand-accent-2: (
    100:var(--white-color),
    700:var(--black-color),
    500: var(--BRAND-ACCENT-2),
    contrast: (
        500: white
    )
);

// mandatory stuff for theming
$palette-brand-accent-1:mat.define-palette($brand-accent-1);
$palette-brand-accent-2:mat.define-palette($brand-accent-2);

$brand-theme: mat.define-light-theme(
   (
    color: (
        primary:$palette-brand-accent-1,
      accent:$palette-brand-accent-2
    ),
    typography: $custom-typography
   )
      
      
    );

@include mat.all-component-themes($brand-theme);

