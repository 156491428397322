@import './variables';

.answer-container {
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 0;
    max-width: 440px;
    margin: 0 auto;
}

.next-btn-container {
    max-width: 440px;
    margin: 0 auto;
}

.disable-container {
    pointer-events: none;
}